const podcastsInfo = {
  en: {
    links: {
      appleFeed: `https://podcasts.apple.com/es/podcast/learn-dutch-with-the-dutch-online-academy/id1501093756`,
      googleFeed: `https://podcasts.google.com/?feed=aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS85MDQ3OTgucnNz`,
      spotifyFeed: `https://open.spotify.com/show/6DiDbSYdB0cNI1taErF5b9`,
    },
    hero: {
      title: `Learn Dutch`,
      subtitle: `A podcast by The Dutch Online Academy`,
    },
    seo: {
      title: `Learn dutch, a podcast by The Dutch Online Academy`,
      description: `Podcasts are a perfect way of doing Dutch language listening on the go. Transcripts available in every episode, classified by level and done with love`,
    },
    ui: {
      goodFor: `Good for`,
      backToPodcast: `See all chapters`,
      podcastSlug: `/podcast`,
    },
  },
  nl: {
    links: {
      appleFeed: `https://podcasts.apple.com/es/podcast/learn-dutch-with-the-dutch-online-academy/id1501093756`,
      googleFeed: `https://podcasts.google.com/?feed=aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS85MDQ3OTgucnNz`,
      spotifyFeed: `https://open.spotify.com/show/6DiDbSYdB0cNI1taErF5b9`,
    },
    hero: {
      title: `Learn Dutch`,
      subtitle: `A podcast by The Dutch Online Academy`,
    },
    seo: {
      title: `Leer Nederlands, een podcast van The Dutch Online Academy`,
      description: `Luister terwijl je leert. Krijg gratis Nederlandse les. Afschriften beschikbaar in elke aflevering, ingedeeld op niveau en met liefde gedaan`,
    },
    ui: {
      goodFor: `Good for`,
      backToPodcast: `See all chapters`,
      podcastSlug: `/en/podcast`,
    },
  },
};

export default podcastsInfo;
