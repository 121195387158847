import styled from "styled-components";
import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";
import { Link } from "gatsby";

export const CardContainer = styled.div`
  /* width: 100%; */
  &.purchased {
    order: 99;
  }
  width: 50%;
  max-width: 50%;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .image {
    width: 40%;
    display: inline-block;
    ${MEDIA.PHONE`
			padding: 0px 42px;`}
    .gatsby-image-wrapper {
      box-shadow: 4px 4px 6px ${(props) => props.theme.default_darkerOne},
        -6px -6px 6px ${(props) => props.theme.default_lighterOne};
    }
  }
  .content {
    width: 60%;
    display: inline-block;
  }
  font-family: ${theme.fontHeaders};
  ${MEDIA.TABLET`
		flex-direction: column;
    width:100%;
    max-width:100%;
		.image,.content{
			width: 100%;

		}
    .image{
      text-align:center;
    }
		
	`}
  margin-bottom: 42px;
`;

export const ContentContainer = styled.div`
  margin: 32px 0px;
  color: ${(props) => props.theme.darkerTwo};
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.default_lighterOne},
    ${(props) => props.theme.default_lighterTwo}
  );
  padding: 30px 22px;
  padding-left: 32px;
  ${MEDIA.MIN_PHONE`
    margin-left: -40px;
    padding-left: 72px;
  `}
  ${MEDIA.TABLET`
		margin-top: -200px;
		padding-top: 230px;
			
    `}
	border-radius: 30px;
  box-shadow: 5px 5px 7px ${(props) => props.theme.default_primary},
    -2px -2px 4px white;
  h1,
  h2,
  h3,
  h4 {
    margin: 0px 0px 14px 0px;
    ${MEDIA.TABLET`
      margin:0
    `}
  }
  .levels-block {
    margin: 6px 5px 0 0;
    display: block;
    > span {
      /* background:${(props) => props.theme.contrast_primary}; */
      border: 1px solid ${(props) => props.theme.default_halfUp};
      border-radius: 2px;
      margin-left: 4px;
      display: inline-block;
      padding: 1px 5px;
    }
  }
  a {
    font-size: 0.8rem;
    font-weight: 700;
  }
`;

export const ProductDetails = styled.ul`
  list-style: none;
  margin: 10px auto 10px -10px;
  padding: 0px;
  li {
    padding: 0px;
    margin: 0px;
    position: relative;
    display: inline-block;
    padding: 2px 10px;
    font-size: 0.9rem;
    text-transform: uppercase;
    &::after {
      content: "";
      position: absolute;
      top: 35%;
      right: -1px;
      bottom: 35%;
      width: 2px;
      height: auto;
      background-color: ${(props) => props.theme.default_darkerOne};
    }
    &:last-child {
      &::after {
        background: none;
      }
    }
  }
`;

export const ButtonPrimary = styled.button`
  padding: 8px 22px;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.halfUp},
    ${(props) => props.theme.primary}
  );
  border-radius: 30px;
  display: block;
  font-weight: 700;
  box-shadow: 5px 5px 7px ${(props) => props.theme.default_primary},
    -2px -2px 4px ${(props) => props.theme.lighterOne};
  color: ${(props) => props.theme.default_lighterTwo};
  border: none;
  font-size: 1rem;
  text-align: center;
  &:hover {
    background: linear-gradient(
      135deg,
      ${(props) => props.theme.darkerTwo},
      ${(props) => props.theme.darkerOne}
    );
  }
`;
export const ButtonContrast = styled.button`
  padding: 8px 22px;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.contrast_halfUp},
    ${(props) => props.theme.contrast_primary}
  );
  border-radius: 30px;
  display: block;
  font-weight: 700;
  box-shadow: 5px 5px 7px ${(props) => props.theme.default_primary},
    -2px -2px 4px ${(props) => props.theme.contrast_lighterOne};
  color: ${(props) => props.theme.default_lighterTwo};
  border: none;
  font-size: 1rem;
  text-align: center;
  &:hover {
    background: linear-gradient(
      135deg,
      ${(props) => props.theme.darkerTwo},
      ${(props) => props.theme.darkerOne}
    );
  }
`;
export const ButtonSecondary = styled(Link)`
  padding: 8px 22px;
  background: none;
  border-radius: 30px;
  border: 2px solid ${(props) => props.theme.primary};
  display: block;
  font-weight: 300;
  box-shadow: none;
  color: ${(props) => props.theme.primary};
  font-size: 0.9rem;
  text-align: center;

  &:hover {
    border: 2px solid ${(props) => props.theme.darkerTwo};
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${MEDIA.PHONE`
		flex-direction: column;
			
    `}
`;

export const OverlineTag = styled.div`
  background: white;
  text-transform: uppercase;
  font-size: 14px;
  padding: 2px 8px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
`;
