const shopInfo = {
  en: {
    hero: {
      title: `Improve your Dutch offline, with detailed books in PDF, from A1 to B2`,
      subtitle: `All PDFs are written by our professional teachers and include explanations, exercises and solutions. In Dutch and English.`,
    },
    body: {
      bottom: `The e-books from The Dutch Online Academy help you learn Dutch in a fun, clear and effective way. Our Orange Books provide clear grammar explanations and exercises (including answers), so you can study at your own pace. The Blue Books contain short stories to help you study dialogues and new vocabulary. All books are PDF-files, so you can study easily on your laptop, tablet or smartphone. `,
    },
    seo: {
      title: `Learn Dutch grammar with books in PDF, from A1 to B2 · #TDOA`,
      description: `Now you can learn Dutch whenever you want with Dutch Grammar Pdf Books. All pdfs are written by our team of professional Dutch teachers from beginner to advance`,
    },
    ui: {
      moreInfo: `More info`,
      backToShop: `Back to all the products`,
      shopSlug: `/en/shop`,
      addToCart: `Add to cart`,
      language: `Language`,
      price: `Price`,
      level: `Level`,
      pages: `Pages`,
      format: `Format`,
      goodFor: `Good for`,
    },
  },
  nl: {
    hero: {
      title: `Verbeter je Nederlands offline, met gedetailleerde uitleg, oefeningen en oplossingen. In Nederlands and Engels.`,
      subtitle: `Alle PDFs worden ontwikkeld door professionele docenten Nederlands.`,
    },
    body: {
      bottom: `The e-books from The Dutch Online Academy help you learn Dutch in a fun, clear and effective way. 
Our Orange Books, the Better Dutch series, provide clear grammar explanations and exercises (including answers), so you can study at your own pace. 
The Blue Books, Dutch Academy Stories, contain short stories to help you study dialogues and new vocabulary. 
All books are PDF-files, so you can study easily on your laptop, tablet or smartphone. `,
    },
    seo: {
      title: `Leer Nederlandse grammatica met boeken in pdf, van A1 tot B2 · #TDOA`,
      description: `Nu kun je Nederlands leren wanneer je maar wilt met Nederlandse grammatica pdf-boeken. Alle pdf's zijn geschreven door ons team van professionele Nederlandse docenten van beginner tot gevorderd`,
    },
    ui: {
      moreInfo: `More info`,
      backToShop: `Back to all the products`,
      shopSlug: `/shop`,
      addToCart: `Add to cart`,
      language: `Language`,
      price: `Price`,
      level: `Level`,
      pages: `Pages`,
      format: `Format`,
      goodFor: `Good for`,
    },
  },
};

export default shopInfo;
