import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import pathBuilder from "../../utils/pathBuilder";
import ContentfulRenderer from "../../components/System/ContentfulRenderer";
import UiLayout from "../../components/UI/Layout";
import Styled from "./styled";
import BuzzSproutPlayer from "./buzzSproutPlayer";
import { Link } from "gatsby";
import podcastsInfo from "../../../data/podcastsInfo";
import Banner from "../../components/banner";
import SocialShare from "../../components/SocialShare";
import RelatedBooksComponent from "../../components/RelatedBooks";
import dateFormat from "dateformat";
import { useAppContext } from "../../context/appProvider";
import CommentsBlock from "../../components/CommentsBlock/CommentsBlock";
import QuizComponent from "../../components/QuizComponent";

const PodcastEpisode = (props) => {
  const ctx = useAppContext();
  const { state, getAccessTokenSilently } = ctx;

  const {
    id,
    // title,
    audio_url,
    artwork_url,
    // description,
    // summary,
    // artist,
    // tags,
    published_at,
    // duration,
    // hq,
    // guid,
    episode_number,
    // season_number,
    // total_plays,
    // buzzsproutId,
    // slug,
    // internal,
  } = props.data.episode;
  // const { BackendGraph } = props.data;
  const {
    title,
    level,
    slug,
    node_locale,
    seoTitle,
    seoDescription,
    contentful_id,
    // buzzsproutId,
    __typename,
    relatedQuiz,
    disableComments,
    // fields,
    subtitle,
    content,
  } = props.data.episodeContent;

  const environment = {
    lang: node_locale,
    id: id,
    path: pathBuilder(node_locale, __typename, slug),
    altHrefLang: [
      {
        lang: node_locale,
        path: pathBuilder(node_locale, __typename, slug),
      },
      {
        lang: props.data.altEpisode.node_locale,
        path: pathBuilder(
          props.data.altEpisode.node_locale,
          props.data.altEpisode.__typename,
          props.data.altEpisode.slug,
        ),
      },
    ],
    theme: "purple",
    seo: {},
  };
  if (seoDescription) {
    environment.seo.description = seoDescription;
  }
  if (seoTitle) {
    environment.seo.title = seoTitle;
  } else {
    environment.seo.title = title;
  }
  const uiContent = podcastsInfo[node_locale];
  const datePublishedAt = dateFormat(published_at, "fullDate");

  return (
    <Layout environment={environment}>
      <Styled.HeroContainer>
        <div className="content">
          <Styled.VideoBox>
            <img src={artwork_url} alt={title} />
          </Styled.VideoBox>
          <Styled.HeroInfo>
            <span className="levelTag">
              Good for: <strong>{level}</strong>
            </span>
            <span>Episode Number:{episode_number}</span>
            <h1>{title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: subtitle.childMarkdownRemark.html,
              }}
            />

            <BuzzSproutPlayer url={audio_url} lang={node_locale} />
            <span>Published at: {datePublishedAt}</span>
            <SocialShare environment={environment} />
          </Styled.HeroInfo>
        </div>
      </Styled.HeroContainer>
      <UiLayout.Content>
        <UiLayout.BodyContainer>
          <UiLayout.Main>
            <Styled.SectionContainer>
              <ContentfulRenderer
                json={content}
                lang={"en"}
                id="Podcast_content"
              />
            </Styled.SectionContainer>
            {relatedQuiz && (
              <Styled.SectionContainer>
                <QuizComponent quiz={relatedQuiz} padding="none" />
              </Styled.SectionContainer>
            )}
            {!disableComments && (
              <CommentsBlock
                getToken={() => getAccessTokenSilently()}
                user={state?.user}
                elementId={contentful_id}
              // comments={BackendGraph.comments}
              />
            )}
          </UiLayout.Main>
          <UiLayout.Aside>
            <Banner />
          </UiLayout.Aside>
        </UiLayout.BodyContainer>
      </UiLayout.Content>
      <Styled.BookSectionWrapper>
        <UiLayout.Content>
          <div className="header-row">
            <h3>Related practice books!</h3>
            <Link to="/en/shop">See all books</Link>
          </div>
        </UiLayout.Content>
        <RelatedBooksComponent level={level} environment={environment} />
      </Styled.BookSectionWrapper>
      <UiLayout.Content>
        <Styled.BackToPodcast>
          <Link to={uiContent.ui.podcastSlug}>
            &larr; {uiContent.ui.backToPodcast}
          </Link>
        </Styled.BackToPodcast>
      </UiLayout.Content>
    </Layout>
  );
};

export default PodcastEpisode;

export const podcastEpisodeQuery = graphql`
  query podcastEpisodeQuery(
    $id: String!
    $buzzsproutId: Int!
    $lang: String!
    $altLang: String!
  ) {
    episode: buzzsproutPodcastEpisode(buzzsproutId: { eq: $buzzsproutId }) {
      id
      title
      audio_url
      artwork_url
      description
      summary
      artist
      tags
      published_at
      duration
      hq
      guid
      episode_number
      season_number
      total_plays
      buzzsproutId
      slug
      internal {
        type
      }
    }
    episodeContent: contentfulPodcastEpisode(
      contentful_id: { eq: $id }
      node_locale: { eq: $lang }
    ) {
      title
      level
      slug
      node_locale
      contentful_id
      buzzsproutId
      seoDescription
      seoTitle
      __typename
      fields {
        altLang
      }
      subtitle {
        childMarkdownRemark {
          html
        }
      }
      content {
        raw
      }
      relatedQuiz {
        ...QuizFragment
      }
      disableComments
    }
    altEpisode: contentfulPodcastEpisode(
      contentful_id: { eq: $id }
      node_locale: { eq: $altLang }
    ) {
      title
      slug
      node_locale
      __typename
    }
    # BackendGraph {
    #   comments: commentsByPost(postId: $id) {
    #     author
    #     comment
    #     dateAdded
    #     id
    #     isByAutor
    #     isResponse
    #     postId
    #     status
    #   }
    # }
  }
`;
