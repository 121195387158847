import { theme } from "../../theme/variables";
import styled from "styled-components";
import MEDIA from "../../theme/helpers/mediaTemplates";

const BookSectionWrapper = styled.div`
  background: ${(props) => props.theme.lighterTwo};
  width: 100%;
  .header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const HeroContainer = styled.div`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.lighterTwo};
  padding: 48px 32px;
  .content {
    max-width: ${theme.contentWideMaxWidth};
    margin: 0 auto;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    ${MEDIA.TABLET`
				flex-direction:column;
			`}
  }
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span {
    font-family: ${theme.fontHeaders};
    color: inherit;
  }
  .mejs-controls {
    .mejs-playpause-button {
      background-color: red !important;
    }
  }
`;

const SectionContainer = styled.div`
  padding: 32px 42px;
  border-radius: 32px;
  margin-bottom: 32px;
  background: linear-gradient(
    135deg,
    white,
    ${(props) => props.theme.default_lighterTwo}
  );
  border: 1px solid ${(props) => props.theme.default_primary};
  /* box-shadow: 5px 5px 7px #00000044,
    -2px -2px 2px ${(props) => props.theme.default_lighterOne}; */
  .header {
    box-shadow: inset 1px 2px 4px ${(props) => props.theme.default_halfUp},
      inset -1px -2px 4px white;
    border: 1px solid ${(props) => props.theme.default_lighterTwo};
    color: ${(props) => props.theme.default_darkerOne};

    display: inline-block;
    text-align: right;
    margin: 0px 0px 10px auto;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 0.9rem;
  }
  h3 {
    font-size: 2rem;
  }
  p {
    a {
      font-weight: 700;
      color: ${(props) => props.theme.darkerTwo};
      border-bottom: 2px solid ${(props) => props.theme.darkerTwo};
    }
  }
  ${MEDIA.PHONE`
		padding:16px;
		h3{
			margin-top: 1.5em;
			font-size: 1.5rem;
			margin-bottom: 0;
		}
	`}
`;

const VideoBox = styled.div`
  img {
    background-color: white;
    max-width: 100%;
    width: 640px;
    height: auto;
    box-shadow: 10px 10px 20px ${(props) => props.theme.darkerOne},
      -5px -5px 10px ${(props) => props.theme.lighterOne};
  }
`;

const HeroInfo = styled.div`
  padding: 64px 32px;
  ${MEDIA.PHONE`
			padding: 32px 0px;
		`}
  max-width: 640px;
  h1 {
    margin: 32px 0px;
  }
  .levelTag {
    display: block;
  }
`;
const ButtonPrimary = styled.button`
  border: none;
  font-weight: 700;
  border-radius: 40px;
  padding: 8px 16px;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.default_lighterTwo},
    ${(props) => props.theme.default_lighterOne}
  );
  box-shadow: 5px 5px 7px ${(props) => props.theme.darkerOne},
    -2px -2px 4px ${(props) => props.theme.lighterOne};

  color: ${(props) => props.theme.darkertTwo};
  font-family: ${theme.fontHeaders};
`;

const BackToPodcast = styled.div`
  display: block;
  margin: 100px 0;
  font-weight: 700;
  text-decoration: underline;
`;

const allStyled = {
  HeroContainer,
  VideoBox,
  HeroInfo,
  ButtonPrimary,
  BackToPodcast,
  BookSectionWrapper,
  SectionContainer,
};
export default allStyled;
