import React, { useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import BookGridItem from "../BookGridItem/BookGridItem";
import pathBuilder from "../../utils/pathBuilder";
// import { RelatedBooksWrapper } from "./styled"
import { pushImpressionView } from "../../utils/data-layer-ecom";
import { ContainerWide, Row } from "../System/Grids";

const TrackingComponent = (props) => {
  useEffect(() => {
    pushImpressionView({
      productList: props.books.map((book) => ({
        id: book.productId,
        name: book.title,
        category: `books`,
        price: book.price,
      })),
      location: "related_books",
    });
  }, []);
  return null;
};

const RelatedBooksComponent = (props) => {
  const { level, environment } = props;
  return (
    <StaticQuery
      query={graphql`
        query ContentfulStudentLevelBlock {
          allContentfulStudentLevel {
            edges {
              node {
                level
                id
                node_locale
                book {
                  title
                  slug
                  price
                  subtitle
                  productId
                  level
                  fileIdSnipcart
                  contentful_id
                  numberOfPages
                  node_locale
                  studentLevel {
                    level
                  }
                  image {
                    fixed: gatsbyImageData(width: 150)
                    main: gatsbyImageData(width: 350, layout: CONSTRAINED)
                  }
                  __typename
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const lvl = data.allContentfulStudentLevel.edges.filter(
          (e) => e.node.level === level,
        );
        const books = lvl.find((e) => e.node.node_locale === environment.lang)
          .node.book;

        // return
        return (
          <ContainerWide>
            <TrackingComponent books={books} />
            <Row>
              {books?.slice(0, 4).map((node, index) => {
                return (
                  <BookGridItem
                    key={node.title}
                    title={node.title}
                    price={node.price}
                    subtitle={node.subtitle}
                    level={node.level}
                    productId={node.productId}
                    studentLevel={node.studentLevel}
                    numberOfPages={node.numberOfPages}
                    // fileIdSnipcart={node.fileIdSnipcart}
                    image={node.image}
                    contentful_id={node.contentful_id}
                    slug={pathBuilder(
                      node.node_locale,
                      node.__typename,
                      node.slug,
                    )}
                    lang={environment.lang}
                    urlSnipcart={
                      environment.lang === "en" ? "/en/shop" : "/shop"
                    }
                  />
                );
              })}
            </Row>
          </ContainerWide>
        );
      }}
    />
  );
};

export default RelatedBooksComponent;
