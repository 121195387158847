import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";

const BuzzSproutPlayer = (props) => {
  const [url, set_url] = useState("");
  useEffect(() => {
    const { url, lang } = props;
    const embedUrl = url.replace(
      ".mp3",
      ".js?container_id=buzzsprout-player-2882512" + lang + "&player=small",
    );

    set_url(embedUrl);
  }, []);

  return (
    <>
      {url.length > 0 ? (
        <Helmet>
          <script src={url} type="text/javascript" charset="utf-8" />
        </Helmet>
      ) : null}
      <div id={"buzzsprout-player-2882512" + props.lang}></div>
    </>
  );
};

export default BuzzSproutPlayer;
